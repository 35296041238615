import * as wjGrid from '@mescius/wijmo.grid';
import store from '@/store';
import { KEYS_CODE } from '@/constants/keyboard'
import {
  BLANK_ID,
  MESSAGE_NOT_NULL,
  ORGANIZATIONAL_DIVISION
}
  from '@/constants/registerData'
import { validateMaximumCharactorOnlyFacility } from '@/utils/validate';
import debounce from 'lodash/debounce'
import { formatValue, $_helper_isNumberType, validateNumberField, math, formatBigNumber, formatEmission25Number } from '../wijmo.helper'
import { handlerFilterData, handlerAddFilterData, addFilterByPasted, getColumnIndexByBinding, getBranchId, checkFilterData } from '@/concerns/utils/filter-data'
import i18n from '@/lang/i18n.js';
import {addThoundSandComma} from '@/utils/convertNumber';
import { getDbCustomizeOrDbMasterById } from '@/concerns/utils/master-or-customize-db';
import { getWidthByTextContent } from '@/utils/calcTextWidth';
export const blankData = (clientRowId) => {
  return {
    clientRowId: clientRowId,
    id: BLANK_ID,
    duration_id: null,
    month: null,
    organizational_division: null,
    company_name: null,
    business_name: null,
    energy_saving_value: null,
    scope12_overlap: null,
    emissions: null,
    year: null,
    type: null,
    source: null,
    memo_1: null,
    memo_2: null,
    country: null,
    layer_3: null,
    layer_4: null,
    layer_5: null,
    layer_6: null,
    db_relation: null,
  }
}

const removeEditColumn = ['emissions'];
const NA_VALUE = 'N/A';

const notNullList = [
  'organizational_division',
  'company_name',
]

const notNullListDbSource = [
  'organizational_division',
  'company_name',
]

const max20Charactor = [
  'organizational_division',
  'energy_saving_value',
  'scope12_overlap',
]

const max128Charactor = [
  'company_name',
  'business_name',
  'source',
  'memo_1',
  'memo_2'
]

const numberField = ['energy_saving_value', 'scope12_overlap', 'total_emissions']

const calcEmissions = (savingValue, overlapValue) => {
  if($_helper_isNumberType(savingValue) && $_helper_isNumberType(overlapValue)){
    const valSaving =  math.bignumber(formatValue(savingValue));
    const valOverLap = math.bignumber(formatValue(overlapValue))
    return  formatBigNumber(math.evaluate(`${valSaving.toString()} - ${valOverLap.toString()}`), 18)
  }

  return 0
}

let isReverted = false;

const filterColumns = ['organizational_division', 'company_name', 'business_name', 'country', 'layer_3', 'layer_4', 'layer_5', 'layer_6']
let listMaxWidths = []

const autoBindingDbSource = dataBinding => {
  const { s, binding, row, currentItem } = dataBinding
  if (binding === 'energy_saving_value') {
    s.deferUpdate(() => {
      const emissions = calcEmissions(currentItem.energy_saving_value, currentItem.scope12_overlap)
      s.setCellData(row, getColumnIndexByBinding(s, 'emissions'), emissions, false, true)
    })
  }

  if (binding === 'scope12_overlap') {
    s.deferUpdate(() => {
      const emissions = calcEmissions(currentItem.energy_saving_value, currentItem.scope12_overlap)
      s.setCellData(row, getColumnIndexByBinding(s, 'emissions'), emissions, false, true)
    })
  }
}


const partern9 = {
  initializeGrid(dataProps) {
    const { flexgrid, itemCount, branchData = {}, getNewBbStore } =  dataProps
    let filterIndex = {};
    let selectedFirst = null;
    let previousCellData = null;
    let checkFilterDataAfterPasted = {};

    flexgrid.scrollPositionChanged.addHandler(debounce((s, e) => {
      if (!store.state.registerData.isFullScreen) {
        return
      }

      if (s.viewRange.bottomRow >= s.rows.length - 1) {
        s.deferUpdate(() => {
          const lastClientId = flexgrid.itemsSource.itemCount

          for (let index = 1; index <= itemCount; index++) {
            s.itemsSource.addNew(blankData(lastClientId + index));
          }

          s.itemsSource.commitNew();
          s.itemsSource.clearChanges();
        });
      }
    }, 100))

    document.addEventListener('keydown', (e) => {
      if (
        (e.metaKey || e.ctrlKey) &&
        [KEYS_CODE.DOWN_ARROW, KEYS_CODE.UP_ARROW, KEYS_CODE.LEFT_ARROW, KEYS_CODE.RIGHT_ARROW, KEYS_CODE.ENTER].includes(e.keyCode)
      ) {
        e.preventDefault();
      }
    });

    flexgrid.hostElement.addEventListener('keydown', (e) => {
      // console.log('keydown: ', e);
      if (e.metaKey || e.ctrlKey) {
        if (e.keyCode === KEYS_CODE.DOWN_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(flexgrid.rows.length - 1, currentSelection.col)
          flexgrid.selection = cellRange

          // re-select after add more
          setTimeout(() => {
            flexgrid.selection = cellRange
          }, 200);
        } else if (e.keyCode === KEYS_CODE.UP_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(0, currentSelection.col)
          flexgrid.selection = cellRange
        } else if (e.keyCode === KEYS_CODE.RIGHT_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(currentSelection.row, flexgrid.columns.length - 1)
          flexgrid.selection = cellRange
        } else if (e.keyCode === KEYS_CODE.LEFT_ARROW) {
          const currentSelection = flexgrid.selection
          const cellRange = new wjGrid.CellRange(currentSelection.row, 1)
          flexgrid.selection = cellRange
        }
      }
      if (e.keyCode === KEYS_CODE.ENTER) {
        if (flexgrid.selection.row === flexgrid.rows.length - 1) {
          const lastClientId = flexgrid.itemsSource.itemCount

          flexgrid.deferUpdate(() => {
            flexgrid.itemsSource.addNew(blankData(lastClientId + 1));

            flexgrid.itemsSource.commitNew();
            // flexgrid.itemsSource.clearChanges();
          });
        }
      }

      
    }, false)

    flexgrid.pasted.addHandler((s, e) => {
      const { col, col2, row, row2 } = e.range
      const view = s.collectionView
      const source = view.sourceCollection

    const dbStore = getNewBbStore()
      const dataStore = dbStore.getDbCustomizeOrDbMasterById
;
      for (let colIndex = col; colIndex <= col2; colIndex++) {
        for (let rowIndex = row; rowIndex <= row2; rowIndex++) {
          // add filter index after pasted
          if (filterColumns.includes(s.columns[colIndex].binding)) {
            if (s.getCellData(rowIndex, colIndex, false) !== null || s.getCellData(rowIndex, colIndex, false) !== undefined) {
              addFilterByPasted(s.columns[colIndex], filterIndex, rowIndex)
              if (typeof checkFilterDataAfterPasted[rowIndex] === 'undefined') {
                checkFilterDataAfterPasted[rowIndex] = {};
              }
              checkFilterDataAfterPasted[rowIndex] = source[rowIndex];
            }
          }

          const cellData = s.getCellData(rowIndex, colIndex, false)
          const currentItem = source[rowIndex] || {}
          const binding = s.columns[colIndex].binding
          const dataBinding = { s, binding, row: rowIndex, cellData, currentItem, dataStore }
          autoBindingDbSource(dataBinding)
        }
      }

      if (Object.values(checkFilterDataAfterPasted).length > 0) {
        const errorMessage = checkFilterData(branchData.filterPatternList, store.state.registerData.layerFilter, checkFilterDataAfterPasted)
        store.dispatch('registerData/updateListErrorFilterDataMessage', errorMessage)
        checkFilterDataAfterPasted = {}
      }
    })

    flexgrid.cellEditEnded.addHandler((s, e) => {
      let column = s.columns[e.col];
      const { row, col } = e.range
      const cellData = s.getCellData(row, col, false)
      const view = s.collectionView
      const source = view.sourceCollection
      const currentItem = source[row] || {}
      const binding = column.binding
      const dbStore = getNewBbStore()
      const dataStore = dbStore.getDbCustomizeOrDbMasterById

      const dataBinding = { s, binding, row, cellData, currentItem, dataStore }
      autoBindingDbSource(dataBinding)

      if (filterColumns.includes(column.binding)) {
        handlerFilterData(column, row, cellData, currentItem, branchData?.filterPatternList, s, filterIndex, selectedFirst, previousCellData, isReverted);
      }
    });

    flexgrid.beginningEdit.addHandler((s, e) => {
      let column = s.columns[e.col];

      if (removeEditColumn.includes(column.binding)) {
        e.cancel = true;
      }

      const { row, col } = e.range
      const cellData = s.getCellData(row, col, false)
      const view = s.collectionView
      const source = view.sourceCollection
      const currentItem = source[row] || {}

      if (filterColumns.includes(column.binding)) {
        previousCellData = cellData;
        handlerAddFilterData(column, row, cellData, currentItem, branchData?.filterPatternList, s, filterIndex, selectedFirst, store.state.registerData, branchData);
      }
    });
    
  },

  header(dataProps) {
    const {
      dbStore,
    } = dataProps

    let headerList =  [
      {
        header: '連番',
        binding: 'id', // id
        minWidth: 40,
        maxWidth: 65,
        allowSorting: false,
        isReadOnly: true,
        visible: false,
        isRequired: false,
      },
      {
        header: i18n.t("56_pattern.summary_score_pattern_name"),
        binding: 'pattern_name',
        minWidth: getWidthByTextContent(i18n.t("56_pattern.summary_score_pattern_name")),
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
      },
      {
        header: i18n.t("56_pattern.summary_score_classify_gics"),
        binding: 'classify_gics',
        minWidth: getWidthByTextContent(i18n.t("56_pattern.summary_score_classify_gics")),
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
      },
      {
        header: i18n.t("56_pattern.summary_score_dq_quality_score"),
        binding: 'dq_quality_score',
        minWidth: getWidthByTextContent(i18n.t("56_pattern.summary_score_dq_quality_score")),
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
      },
      {
        header: i18n.t("56_pattern.summary_score_total_emissions"),
        binding: 'total_emissions',
        cssClassAll: 'auto-increment emission',
        align: 'right',
        minWidth: getWidthByTextContent(i18n.t("56_pattern.summary_score_total_emissions")),
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
      },
     
    ];

    const removeKeyItemVByType = dbStore?.summaryScoreType === 0 ? 'pattern_name' : 'classify_gics'
    headerList = headerList.filter(header => header.binding !== removeKeyItemVByType)
    return headerList
  },

  handlerDataOnTable(dataProps) {
    const {
      dataTable,
      bindingLayout,
      isSaveTemplate,
      branchData,
      isGetBranchDetail,
      dbStore
    } = dataProps
    // dataTable.energy_saving_value = dataTable.energy_saving_value && formatValue(dataTable.energy_saving_value);
    // dataTable.scope12_overlap = dataTable.scope12_overlap && formatValue(dataTable.scope12_overlap);
    const emissions = dataTable.total_emissions;
   // formatValue(emissions)?.substring(0, 20)formatValue(emissions)?.substring(0, 20);
    // auto set data for layout
    const layoutsData = {}
    bindingLayout.forEach(key => {
      layoutsData[key] = dataTable[key] || null
    })
    const branchId = isGetBranchDetail ? getBranchId(branchData.filterPatternList, {
      organizational_division: dataTable.organizational_division,
      company_name: dataTable.company_name,
      ...layoutsData
    }) : {};

    if (typeof dataTable.evidence === 'string') { 
      dataTable.evidence = JSON.parse(dataTable.evidence);
    }
    let dataTableItemCustom = {
      id: dataTable.id,
      company_name: dataTable.company_name || null,
      organizational_division: dataTable.organizational_division || null,
      emissions: formatEmission25Number(emissions),
      source: dataTable.source || null,
      energy_saving_value: isSaveTemplate ? '' : formatValue(dataTable.energy_saving_value) === 0 ? '0' : formatValue(dataTable.energy_saving_value) || null,
      scope12_overlap: isSaveTemplate ? '' : formatValue(dataTable.scope12_overlap) === 0 ? '0' : formatValue(dataTable.scope12_overlap) || null,
      contractor_id: store.state.userData.contractor, // from store
      month: store.state.registerData.month, // from store
      year: store.state.registerData.yearSelected,
      type: 0,
      memo_1: dataTable.memo_1 || null,
      memo_2: dataTable.memo_2 || null,
      ...layoutsData,
      branch_id: branchId?.id || null,
      evidence: dataTable.evidence || null,
      status: dataTable.status ? 1 : 0,
    };
      
    if (dataTable.evidence) {
      if (dataTable.is_primary_data) {
        delete dataTable.evidence.value;
      }
      else {
        dataTableItemCustom.evidence.value = dataTable[this.bindingValueColumn];
      }
    }
    if (isSaveTemplate) {
      dataTableItemCustom.evidence = null;
    }
    return dataTableItemCustom;
  },

  addBlankItemsToView: (view, count) => {
    const lastClientId = view.itemCount
    for (let index = 1; index <= count; index++) {
      view.addNew(blankData(lastClientId + index));
    }

    view.commitNew();
    // view.clearChanges();
  },

  filterColumns: ['organizational_division', 'company_name', 'business_name'],
  getError(item, propName, dbStore) {

    const listItemvalidate = item.db_relation ? notNullListDbSource : notNullList

    if (listItemvalidate.includes(propName)) {
      if (item[propName] === null || item[propName] === '' || item[propName] === undefined || !item[propName] && item[propName] !== 0) {
        return MESSAGE_NOT_NULL;
      }
    }

    if (numberField.includes(propName)) {
      if (!item[propName] && item[propName] !== 0) {
        return MESSAGE_NOT_NULL
      } else {
        return validateNumberField(String(item[propName]), propName);
      }
    }

    if (max20Charactor.includes(propName)) {
      if (numberField.includes(propName)) {
        return validateNumberField(String(item[propName]), propName);
      }
      return validateMaximumCharactorOnlyFacility(String(item[propName]), 20);
    }

    if (max128Charactor.includes(propName)) {
      return validateMaximumCharactorOnlyFacility(String(item[propName]), 128);
    }
    return null
  },

  cellInputInView(bindingLayout) {
    return [
      'organizational_division',
      'company_name',
      'memo_1',
      'memo_2',
      'energy_saving_value',
      'scope12_overlap',
      'source',
      'attach_file',
      ...bindingLayout
    ];
  },

  cellRequireInView(bindingLayout) {
    return [
      'organizational_division',
      'company_name',
      'memo_1',
      'memo_2',
      'energy_saving_value',
      'scope12_overlap',
      'source',
      ...bindingLayout
    ];
  },

  autoFields: [],

  patternUrl: '/pattern-s3-c4/2',

  calcEmissions(rowData) {
    return calcEmissions(rowData.energy_saving_value, rowData.scope12_overlap)
  },

  getMaxWidthByContent(listDataView, columns, getWidthbyContent, theGrid) {
    let resultArrayWidth = [];

    columns.forEach((column) => {
      const paddingDatamap = column?.dataMap ? 10 : 0;
      let indexMaxLengthOfColumn = listDataView?.reduce((indexMaxLengthOfColumn, rowData, index) => {
        let dataLength = rowData?.[column.binding]?.toString()?.length;
        let dataLengthInView = listDataView[indexMaxLengthOfColumn]?.[column.binding]?.toString()?.length
        let curentDataInView = listDataView[indexMaxLengthOfColumn]

        // check length
        const maxlength128 = 128;
        const maxlength20 = 20;
        if(max128Charactor.includes(column.binding) && dataLength > maxlength128) {
          const newData = rowData?.[column.binding]?.toString()?.slice(0, maxlength128)
          theGrid.setCellData(index, getColumnIndexByBinding(theGrid, column.binding), newData, false, true)
          dataLength = maxlength128
        }

        if(max20Charactor.includes(column.binding) && formatValue(rowData?.[column.binding])?.toString()?.length > maxlength20) {
          const emission20Number = formatValue(rowData?.[column.binding])?.includes('-') ? 21 : 20;
          const newData = addThoundSandComma(formatValue(rowData?.[column.binding])?.substring(0, emission20Number)) || '0';
          theGrid.setCellData(index, getColumnIndexByBinding(theGrid, column.binding), newData, false, true)
          dataLength = maxlength20
        }
        //end check length
        
        if(column.binding === 'organizational_division' && rowData?.organizational_division) {
          const organizationList = ['単体', '子会社', '関連会社', 'その他関連会社']
           const realValOrganization = rowData?.organizational_division ? organizationList[rowData?.organizational_division - 1] : '単体'
          const realMaxValOrganization = curentDataInView?.organizational_division ? organizationList[curentDataInView?.organizational_division - 1] : '単体'
          dataLength = realValOrganization.length
          dataLengthInView = realMaxValOrganization.length
        }
        if(dataLength && !dataLengthInView) {
          return index
        }
        return dataLength > dataLengthInView ? index : indexMaxLengthOfColumn
      }, 0);

      const dataMaxLengthInview = listDataView[indexMaxLengthOfColumn];
      let contentMaxWidth = dataMaxLengthInview?.[column.binding]
      if(column.binding === 'organizational_division') {
        contentMaxWidth = ORGANIZATIONAL_DIVISION[dataMaxLengthInview.organizational_division - 1]
      }

      const widthOfCellMaxlengh = getWidthbyContent(contentMaxWidth) + paddingDatamap
      const widthHeaderOfCellMaxLength = getWidthbyContent(column.header, true) 
      resultArrayWidth.push(widthOfCellMaxlengh > widthHeaderOfCellMaxLength ? widthOfCellMaxlengh : widthHeaderOfCellMaxLength)
    })
    listMaxWidths = resultArrayWidth
    return resultArrayWidth
  },

  handleResizeGrid(theGrid, listColumn, listWidth, callBack) {
    let paddingCell = 28

    listColumn.forEach((column, index) => {
      let width = listWidth[index]
      if(width < column.minWidth) {
        width = column.minWidth
        if(column.dataMap) {
          width = column.minWidth - paddingCell
        }
      } else if (width > column.maxWidth) {
        width = column.maxWidth
      }
      callBack(theGrid, index, column.dataMap ? width + paddingCell : width);
    })
  },

  handleResizeWhenCellEdit(theGrid, event, callBackGetWidth, callBackSetWidth) {
    const paddingCell = 28;
    const {row, col} = event.range;
    const cellData = theGrid.getCellData(row, col, false);
    if(!cellData) return 

    const isDataMapCell = theGrid.columnHeaders.columns[col]?.dataMap;
    let widthCellEditing = isDataMapCell ? callBackGetWidth(cellData) + paddingCell : callBackGetWidth(cellData);

    if(listMaxWidths[col] < widthCellEditing) {
      callBackSetWidth(theGrid, col, widthCellEditing);
      listMaxWidths[col] = widthCellEditing
    }
  },

  handleResizeWhenPasted(theGrid, event, columns, callBackGetWidth, callBackSetWidth) {
    const {row, row2} = event.range;
    const source = theGrid.collectionView.sourceCollection.slice(row, row2);

    columns.forEach((column, indexColumn) => {
      let indexMaxLengthOfColumn = source.reduce((indexMaxLengthOfColumn, rowData, index) => {
        let dataLength = rowData?.[column.binding]?.toString()?.length;
        let dataLengthInView = source[indexMaxLengthOfColumn]?.[column.binding]?.toString()?.length

        // check length
        const maxlength128 = 128;
        const maxlength20 = 20;
        if(max128Charactor.includes(column.binding) && dataLength > maxlength128) {
          const newData = rowData?.[column.binding]?.toString()?.slice(0, maxlength128)
          theGrid.setCellData(index, getColumnIndexByBinding(theGrid, column.binding), newData, false, true)
          dataLength = maxlength128
        }

        if(max20Charactor.includes(column.binding) && formatValue(rowData?.[column.binding])?.toString()?.length > maxlength20) {
          const emission20Number = formatValue(rowData?.[column.binding])?.includes('-') ? 21 : 20;
          const newData = addThoundSandComma(formatValue(rowData?.[column.binding])?.substring(0, emission20Number)) || '0';
          theGrid.setCellData(index, getColumnIndexByBinding(theGrid, column.binding), newData, false, true)
          dataLength = maxlength20
        }
        //end check length
        
        if(column.binding === 'organizational_division' && rowData?.organizational_division) {
          const organizationList = ['単体', '子会社', '関連会社', 'その他関連会社']
           const realValOrganization = rowData?.organizational_division ? organizationList[rowData?.organizational_division - 1] : '単体'
          const realMaxValOrganization = curentDataInView?.organizational_division ? organizationList[curentDataInView?.organizational_division - 1] : '単体'
          dataLength = realValOrganization.length
          dataLengthInView = realMaxValOrganization.length
        }
        if(dataLength && !dataLengthInView) {
          return index
        }
        return dataLength > dataLengthInView ? index : indexMaxLengthOfColumn
      }, 0);

      const dataMaxLengthInview = source[indexMaxLengthOfColumn];
      let contentMaxWidth = dataMaxLengthInview?.[column.binding]
      const widthOfCellPasteingMaxlengh = callBackGetWidth(contentMaxWidth)

      if(listMaxWidths[indexColumn] < widthOfCellPasteingMaxlengh) {
        callBackSetWidth(theGrid, indexColumn, widthOfCellPasteingMaxlengh)
        listMaxWidths[indexColumn] = widthOfCellPasteingMaxlengh
      }
    })
  },
  bindingValueColumn: 'energy_saving_value',
};

export default partern9;
